import { api } from "../Utils/constants";

export async function aadhaarAuthentication(data, accessToken, type) {
  const response = await fetch(`${api.baseUrl}/citizen/aadhaar/${type}`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-auth-token": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}
