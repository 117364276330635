import React, { useState } from "react";
import InputBox from "../InputBox/InputBox";
import OTPInputBox from "../OTPInputBox/OTPInputBox";
import Button from "../Button/Button";
import {
  sendOTP,
  setupRecaptcha,
  verifyOtp,
} from "../../Services/authentication";
import { handleErrors } from "../../Utils/constants";

export default function OTPLogin(props) {
  const [loading, setLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [verificationId, setVerificationId] = useState(null);

  const formChange = (event) => {
    const { name, value } = event.target;
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      name === "phoneNumber" &&
      verificationId &&
      props.formData.phoneNumber !== value
    ) {
      setVerificationId(null);
    }
    if (name !== "otp") {
      props.setFormData({
        ...props.formData,
        [name]: value,
      });
    }
  };

  const setOTPValue = (otp) => {
    props.setFormData({
      ...props.formData,
      OTP: otp,
    });
  };

  const onSendOTP = async (phoneNumber) => {
    setLoading(true);
    try {
      setupRecaptcha();
      const result = await sendOTP(phoneNumber);
      setVerificationId(result);
      if (result) {
        setIsOtpSent(true);
      }
    } catch (error) {
      console.error(error);
      props.setSnackBarData(handleErrors(error));
    }
    setLoading(false);
  };

  const onVerifyOtp = async (OTP) => {
    setLoading(true);
    try {
      if (!verificationId) {
        console.warn("Verification ID is missing.");
        setLoading(false);
        return;
      }
      const accessToken = await verifyOtp(OTP, verificationId);
      if (accessToken) {
        props.setIsAuth(accessToken);
      }
    } catch (error) {
      console.error(error);
      props.setSnackBarData(handleErrors(error));
    }
    setLoading(false);
  };

  const isOtpButtonDisabled = () => {
    const { phoneNumber } = props.formData;
    return !(phoneNumber.trim().length >= 13 && !loading);
  };

  return (
    <>
      <form
        className="inherit-parent-height inherit-parent-width max-width-500px flex-justify-content-space-between flex-direction-column"
        onChange={(event) => {
          formChange(event);
        }}
        onSubmit={(event) => event.preventDefault()}
      >
        <div
          className={`background-white padding-left-larger ${
            props.windowInnerWidth <= 767 ? "padding-right-larger" : ""
          }`}
        >
          <div className="height-64px" />
          <InputBox
            className="inherit-parent-width border-radius-default margin-bottom-large"
            name="phoneNumber"
            label="PHONE NUMBER"
            type="number"
            autoFocus={true}
            autoComplete="off"
            value={props.formData.phoneNumber}
            buttonTileId="login-button"
            buttonTile={isOtpSent ? "RESEND OTP" : "SEND OTP"}
            buttonType={isOtpSent ? "button" : "submit"}
            onButtonClick={(event) => {
              event.preventDefault();
              onSendOTP(props.formData.phoneNumber);
            }}
            data-cy="login-screen-phone-number-field"
            required
            suffixButtonDisabled={isOtpButtonDisabled()}
            validation={(value) => {
              if (
                !(/^\d{10}$/.test(value.split("+91")[1]) || value === "+91")
              ) {
                return {
                  status: false,
                  message: "ENTER A VALID PHONE NUMBER",
                };
              }
            }}
          />
          {isOtpSent && verificationId && (
            <OTPInputBox
              size="small"
              name="otp"
              label="ENTER YOUR OTP"
              setValue={setOTPValue}
              className="margin-bottom-large"
              required
            />
          )}
        </div>
        <footer
          className={`padding-left-larger padding-top-larger padding-bottom-larger inherit-parent-width max-width-500px ${
            props.windowInnerWidth <= 767 ? "padding-right-larger" : ""
          }`}
        >
          <Button
            data-cy={loading ? "login-loading-button" : "login-button"}
            loading={loading}
            type={isOtpSent === "string" ? "submit" : "button"}
            className=""
            text="LOGIN"
            onClick={(event) => {
              event.preventDefault();
              onVerifyOtp(props.formData.OTP);
            }}
            disabled={
              !(
                typeof props.formData.OTP === "string" &&
                props.formData.OTP.length === 6
              )
            }
            boxShadow={false}
          />
        </footer>
        <div id="recaptcha-container"></div>
      </form>
    </>
  );
}
