import { errorHandler } from "../Services/error";

export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://api.dev.origin25.byepo.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "https://api.uat.origin25.byepo.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://o25-api-prod.web.app"
      : "http://127.0.0.1:5000",
};

export const logRocketInit = {
  key:
    process.env.REACT_APP_STAGING === "development"
      ? "byepo/o25-jobfair-dev"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "byepo/o25-jobfair-uat"
      : process.env.REACT_APP_STAGING === "production"
      ? "byepo/o25-jobfair-prod"
      : "byepo/o25-jobfair-dev",
};

export function rippleEffect(event) {
  const parentElement = event.currentTarget;
  const containerElement = event.currentTarget.parentElement;
  const viewportOffset = parentElement.getBoundingClientRect();
  parentElement.classList.add("ripple-element");

  const circle = document.createElement("span");
  circle.classList.add("ripple-span");
  const diameter = Math.max(
    parentElement.clientWidth,
    parentElement.clientHeight
  );
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${
    event.clientX +
    containerElement.scrollLeft -
    parentElement.offsetLeft -
    radius
  }px`;
  circle.style.top = `${
    event.clientY + containerElement.scrollTop - viewportOffset.y - radius
  }px`;
  circle.classList.add("ripples");

  const ripple = parentElement.getElementsByClassName("ripples")[0];

  if (ripple) {
    ripple.remove();
  }
  //Add ripple element
  parentElement.appendChild(circle);
}

export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^\d{12}$/,
  phoneNumber: /^\d{10}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){2,13}[a-zA-Z]$/i,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const isPureNumber = (num) => {
  const pureNumRegX = new RegExp(/^[0-9]*\.?[0-9]*$/);
  return pureNumRegX.test(num);
};

export const isValidArray = (array) => {
  return Array.isArray(array) && array.length > 0;
};

export const bucketNames = {
  defaultBucket:
    process.env.REACT_APP_STAGING === "development"
      ? "gs://origin-25-dev.appspot.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "gs://origin-25-uat.appspot.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "gs://origin-25-prod.appspot.com"
      : "gs://origin-25-dev.appspot.com",
};

export const handleErrors = (inComingError) => {
  const error = errorHandler(inComingError);
  console.log(error);
  return {
    code: error.code,
    message: error.message,
  };
};
