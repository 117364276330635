import { auth } from "./firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
export const setupRecaptcha = () => {
  if (window.Cypress || process.env.REACT_APP_STAGING === "local") {
    auth.settings.appVerificationDisabledForTesting = true;
  }
  if (window.recaptchaVerifier) {
    return;
  }
  window.recaptchaVerifier = new RecaptchaVerifier(
    "recaptcha-container",
    { size: "invisible" },
    auth
  );
};

export const sendOTP = async (phoneNumber) => {
  const appVerifier = window.recaptchaVerifier;
  const confirmationResult = await signInWithPhoneNumber(
    auth,
    phoneNumber,
    appVerifier
  );
  return confirmationResult.verificationId;
};

export const verifyOtp = async (OTP, verificationId) => {
  const credential = PhoneAuthProvider.credential(verificationId, OTP);
  const result = await signInWithCredential(auth, credential);
  return result.user.accessToken;
};
