import {
  ref,
  getStorage,
  uploadBytes,
  connectStorageEmulator,
} from "firebase/storage";
import { getApp } from "@firebase/app";
import { bucketNames } from "../Utils/constants";
const switchBucket = (bucketName) => {
  if (process.env.REACT_APP_STAGING === "local") {
    connectStorageEmulator(getStorage(getApp(), bucketName), "localhost", 9199);
  }
  return getStorage(getApp(), bucketName);
};
export async function uploadReferencePicture(file, fileName) {
  const customStorage = switchBucket(bucketNames.defaultBucket);
  const filePath = `/resumes/${fileName}`;
  await uploadBytes(ref(customStorage, filePath), file);
  console.log(`${customStorage._url}${filePath}`, "name");
  return `${customStorage._url}${filePath}`;
}
