import "./SuccessScreen.css";
import Button from "../Button/Button";

export default function SuccessScreen(props) {
  return (
    <div className="inherit-parent-height inherit-parent-width display-flex flex-justify-content-center flex-direction-column padding-large">
      <div
        style={{ height: "calc(100% - 44px)" }}
        className="font-size-larger font-family-RHD-medium flex-justify-content-center flex-align-items-center flex-direction-column"
      >
        <div className="wrapper">
          <svg className="checkmark" viewBox="0 0 52 52">
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>

        <div
          style={{ fontSize: "20px" }}
          className="text-align-center padding-top-medium"
        >
          {props.text}
        </div>
      </div>

      <div className="inherit-parent-width flex-justify-content-center padding-top-larger">
        <Button
          data-cy={"continue-to-home-button"}
          type="button"
          text={props.buttonText}
          boxShadow={false}
          onClick={() => {
            props.continueOnClick();
          }}
        />
      </div>
    </div>
  );
}
