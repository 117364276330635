import React, { useState } from "react";
import InputBox from "../InputBox/InputBox";
import { Regex } from "../../Utils/constants";
import OTPInputBox from "../OTPInputBox/OTPInputBox";
import Button from "../Button/Button";
import { aadhaarAuthentication } from "../../Services/api";

export default function AadhaarOTPLogin(props) {
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(null);

  const onVerifyAadhaarOtp = async (OTP) => {
    setLoading(true);
    try {
      if (!transactionId) {
        console.warn("transactionId ID is missing.");
        setLoading(false);
        return;
      }

      const body = {
        phoneNumber: props.phoneNumber,
        transactionId: transactionId,
        otp: OTP,
      };

      const response = await aadhaarAuthentication(
        body,
        props.isAuth,
        "verifyOtp"
      );
      if (response.success) {
        props.setShowScreen({ success: false, upload: true });
      }
    } catch (error) {
      console.error("Error verifying aadhaar OTP:", error.message);
    }
    setLoading(false);
  };

  const onSendAadhaarOTP = async (aadhaarNumber) => {
    setLoading(true);

    try {
      const body = {
        aadhaarNumber: aadhaarNumber,
      };
      const response = await aadhaarAuthentication(
        body,
        props.isAuth,
        "sendOtp"
      );
      setTransactionId(response.data.client_id);
    } catch (error) {
      console.error("Error sending aadhaar OTP", error);
    }
    setLoading(false);
  };

  return (
    <>
      <form
        className="inherit-parent-height inherit-parent-width max-width-500px flex-justify-content-space-between flex-direction-column"
        onChange={(event) => {
          if (event.target.name === "aadhaarNumber") {
            props.setAadhaarFormData({
              ...props.aadhaarFormData,
              [event.target.name]: event.target.value,
            });
          }
        }}
        onSubmit={(event) => {
          event.preventDefault();
          onVerifyAadhaarOtp(props.aadhaarFormData.OTP);
          props.setAadhaarFormData({
            aadhaarNumber: "",
            OTP: null,
          });
        }}
      >
        <div
          className={`background-white padding-left-larger ${
            props.windowInnerWidth <= 767 ? "padding-right-larger" : ""
          }`}
        >
          <div className="height-64px" />
          <InputBox
            className="inherit-parent-width border-radius-default margin-bottom-large"
            name="aadhaarNumber"
            label="ENTER AADHAAR NUMBER"
            type="number"
            autoFocus={true}
            autoComplete="off"
            value={props.aadhaarFormData.aadhaarNumber}
            buttonTileId="aadhaar-send-otp-button"
            buttonTile={transactionId !== null ? "RESEND OTP" : "SEND OTP"}
            buttonType={transactionId ? "button" : "submit"}
            onButtonClick={(event) => {
              event.preventDefault();
              onSendAadhaarOTP(props.aadhaarFormData.aadhaarNumber);
            }}
            data-cy="register-screen-aadhaar-number-field"
            required
            suffixButtonDisabled={
              loading ||
              !Regex.aadhaarNumber.test(props.aadhaarFormData.aadhaarNumber)
            }
            validation={(aadhaarNumber) => {
              if (!Regex.aadhaarNumber.test(aadhaarNumber)) {
                return {
                  status: false,
                  message: "ENTER VALID AADHAAR NUMBER",
                };
              }
            }}
          />
          {transactionId && (
            <OTPInputBox
              name="otp"
              label={"ENTER OTP"}
              setValue={(otp) => {
                props.setAadhaarFormData({
                  ...props.aadhaarFormData,
                  OTP: otp,
                });
              }}
              className="margin-bottom-large"
              required
            />
          )}
        </div>
        <footer
          className={`padding-left-larger padding-top-larger padding-bottom-larger inherit-parent-width max-width-500px ${
            props.windowInnerWidth <= 767 ? "padding-right-larger" : ""
          }`}
        >
          <Button
            data-cy={
              loading ? "aadhaar-login-loading-button" : "aadhaar-login-button"
            }
            loading={loading}
            type={transactionId ? "submit" : "button"}
            text={"CONTINUE"}
            disabled={
              !(
                typeof props.aadhaarFormData.OTP === "string" &&
                props.aadhaarFormData.OTP.length === 6
              )
            }
            boxShadow={false}
          />
        </footer>
      </form>
    </>
  );
}
